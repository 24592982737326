@import url(https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,500;0,900;1,200&display=swap);


* {
  margin: 0;
  font-family: Mulish, Helvetica, sans-serif;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

*::-webkit-scrollbar {
  display: none;
}

.app{
  width: 1fr;
  height: 100vh;
  display: grid;
  grid-template-columns: 5% 1fr 1fr 1fr 5%;
  grid-template-rows: 30% 10% 2% 58% 5%;
  grid-template-areas:
    'header header header header header'
    '. maintxt maintxt maintxt .'
    '. line line line .'
    '. our-app media help .'
    '. footer footer footer .'
    ;
    grid-gap: 5px;
    gap: 5px;
}

.header {
  grid-area: header;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image:
    linear-gradient(
      to top right,
      #E0CD9C, #CFF4FF
    );
}

.header > img {
  height: 200px;
}

.maintxt {
  grid-area: maintxt;
  /* TODO: Que pedo con los putos weights de la verga */
  font-weight: normal;
  place-self: center;
  text-align: center;
}

h2 {
  font-size: 1.3em;
  color: #1F233F;
}

.line {
  grid-area: line;
  align-self: center;
  background-image:
    linear-gradient(
      to right,
      #E0CD9C, #CFF4FF
    );
  height: 3px;
  border-radius: 10px;
}

.our-app {
  grid-area: our-app;
}

.our-app > div {
  height: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.our-app > div > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0 10px 0;
}

.our-app > div > div:first-child > img {
  padding-right: 15px;
}

.our-app > div > div:nth-child(2) > img {
  padding-right: 15px;
}

.our-app > div > div > img {
  height: 50px;
  padding-right: 10px;
}

.our-app > div > div:last-child > img {
  height: 60px;
}

h3 {
  font-family: Mulish;
  font-size: 1.7em;
  color: #1F233F;
  font-weight: 600;
}

.media {
  grid-area: media;
  height: 90%;
  align-self: end;
  overflow: hidden;
}

.media > div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 5px;
}

.media > div > a > img {
  height: 25px;
}

.getouttahere {
  height: 90%;
}

.getouttahere > img {
  height: 300px;
}

.help {
  grid-area: help;
  justify-self: end;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 90%;
  height: 70%;
}

.help:hover {
  cursor: pointer;
}

.comunidad {
  font-weight: bold;
}

.help > a > button {
  background-color: #1F233F;
  color: white;
  font-weight: normal;
  align-self: center;
  width: 70%;
  padding: 13px 20px 13px 20px;
  margin-top: 20px;
  border-radius: 10px;
  border: none;
}

.help > a > button:hover {
  background-color: #E0A261;
}

img {
  height: 30px;
}

.footer{
  grid-area: footer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: .5rem;
  text-decoration: none;
}

.footer > p:first-child{
  margin-right: 5px;
}

@media (max-width: 800px) {
  .app {
    grid-template-columns: 5% 1fr 5%;
    grid-template-rows: 200px 150px 40px 400px 400px 400px 50px;
    grid-template-areas: 'header header header'
                         '. maintxt .'
                         '. line .'
                         '. media .'
                         '. our-app .'
                         '. help .'
                         '. footer .';
      grid-gap: 5px;
      gap: 5px;
  }

  .help{
    place-self: center;
  }

  .our-app{
    width: 90%;
    place-self: center;
  }

  .header > img {
    height: 100px;
  }

}

@media (min-width: 750px) and (max-width: 1000px) {
  .header > img {
    height: 150px;
  }

  *{
    font-size: 1.2rem;
  }
}


